import React from 'react';
import SearchIcon from "../../assets/search-small.svg";
import ClearIcon from "../../assets/clear.svg";
import MagnifyingGlass from "../../assets/magnifying-glass.svg";
import EmptySearchResults from "../../assets/empty-search-results.svg";
import SearchBar from '../../components/SearchBar/SearchBar'
import ChevronRight from "../../assets/chevron-right.svg";
import ListItem from "../../components/ListItem/ListItem";
import CurvedListItemContainer from '../../components/CurvedListItemContainer/CurvedListItemContainer';
import ArticleSearchInstruction from '../../components/ArticleSearchInstruction/ArticleSearchInstruction';
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { getArticles } from '../../services/articles';
import { debounce, withRouter, withSnackBar, getSnackbarArgs } from '../../utils/utils';
import { ApiResponse } from '../../types/auth';
import { sendScribeEvent } from "../../utils/scribeHelper";
import i18n from '../../i18n/config';

const duration = 500;

interface Article {
    id: number,
    title: string,
    preview: string
}

interface ComponentState {
    inputText: string,
    articles: Array<Article>,
    loading: boolean,
    inputBarInFocus: boolean
}

class SearchPage extends React.Component<any, ComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputText: "",
            articles: [],
            loading: false,
            inputBarInFocus: false
        }

        this.updateInputText = this.updateInputText.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    componentDidMount() {
        if (window.history.state["inputText"]) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    inputText: window.history.state["inputText"]
                }
            })
        }
        if (window.history.state["articles"]) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    articles: window.history.state["articles"]
                }
            })
        }
    }

    onClickCancel(event: any) {
        this.props.navigate(-1);
    }

    updateArticleList = debounce(async (value: string) => {
        this.setState({ loading: true });
        let searchArticlesResponse = await this.searchArticles(value);
        // if (searchArticlesResponse.error)
        //     this.props.openSnackbar(getSnackbarArgs({
        //         onClose: this.props.closeSnackbar,
        //         uniqueKey: "search-articles",
        //     }))

        this.setState({
            articles: searchArticlesResponse.res,
            loading: false
        });
    })

    updateInputText(value: string) {
        let regexPattern = new RegExp("^(?![ !@,.?<:>;'\"|#$%^&*]{3}).*");
        this.setState({
            inputText: value,
        });
        // console.log(regexPattern.test(value));
        if (value.length >= 3) {
            if (regexPattern.test(value)) 
                this.updateArticleList(value)
            else 
                this.setState({
                    articles: []
                })
        }
        else
            this.setState({
                articles: []
            })
    }

    async searchArticles(value: string) {
        const params = { queryString: value }
        try {
            let res = await getArticles(params);
            return { res: res.articles as Array<Article> } as ApiResponse
        } catch (error) {
            console.error(error)
            return { res: [] as Array<Article>, error } as ApiResponse
        }

    }

    onInputBarFocus = () => {
        this.setState({ inputBarInFocus: true })
    }

    onInputBarBlur = () => {
        this.setState({ inputBarInFocus: false })
    }

    getSearchInstructions = (articleSearchInstruction: React.ReactElement) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    transition: `margin-top ${duration}ms ease-in-out`,
                    mt: this.state.inputBarInFocus ? '60px' : '0px',
                }}
            >
                {articleSearchInstruction}
                <Box
                    sx={{
                        transition: `flex ${duration}ms ease-in-out`,
                        flex: this.state.inputBarInFocus ? 1 : 0,
                    }}
                />
            </Box>
        )
    }

    pushState = () => {
        window.history.replaceState(
            { ...window.history.state, "inputText": this.state.inputText, "articles": this.state.articles },
            document.title
        );
    }

    render() {
        const beforeSearchInstructions = this.getSearchInstructions(
            <ArticleSearchInstruction
                icon={MagnifyingGlass}
                primaryText={i18n.t("helpcentre_search_page_how_can_we_help")}
                secondaryText={i18n.t("helpcentre_search_page_search_help_centre")}
            />
        )
        const emptySearchResultsInstructions = this.getSearchInstructions(
            <ArticleSearchInstruction
                icon={EmptySearchResults}
                primaryText={i18n.t("helpcentre_search_page_that_is_new")}
                secondaryText={i18n.t("helpcentre_search_page_try_search")}
            />
        )

        const articleSearchResults = <Box>
            <CurvedListItemContainer>
                {this.state.articles.map((article: Article, index: number) => {
                    return (
                        <Link 
                        key={'article-list-item-' + index}
                        to={`/article/${article.id}`}
                        state={{from: "search-article"}}
                        onClick={() => this.pushState()} className='no-text-decoration'>
                            <ListItem
                                primaryText={
                                    <Typography
                                        variant="body-02"
                                        sx={{
                                            color: "text-primary.main",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        className="line-clamp"
                                    >
                                        {article.title}
                                    </Typography>
                                }
                                secondaryText={
                                    <Typography
                                        variant="body-03"
                                        sx={{
                                            color: "text-tertiary.main",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        className="line-clamp"
                                    >
                                        {article.preview}
                                    </Typography>
                                }
                                secondaryIcon={
                                    <IconButton><img src={ChevronRight} alt="" /></IconButton>
                                }
                                isCurved={false}
                            />
                        </Link>
                    )
                })}
            </CurvedListItemContainer>
        </Box>

        const loader = <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={80} />
        </Box>

        let content: any = articleSearchResults;
        if (this.state.loading)
            content = loader;
        else if (this.state.inputText === '')
            content = beforeSearchInstructions;
        else if (this.state.articles.length === 0) {
            sendScribeEvent("help_search_landing_submit", { "search_text": this.state.inputText, "search_num_results": 0, "search_results": false })
            content = emptySearchResultsInstructions;
        }
        return (
            <Box display="flex" flexDirection="column">
                <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between" spacing={3} mb={6}>
                    <Box width="100%">
                        <SearchBar
                            autoFocus={true}
                            placeholder="Tell us what you need"
                            primaryIcon={SearchIcon}
                            secondaryIcon={ClearIcon}
                            value={this.state.inputText}
                            onChange={this.updateInputText}
                            onFocus={this.onInputBarFocus}
                            onBlur={this.onInputBarBlur}
                        />
                    </Box>
                    <Button variant="text" onClick={this.onClickCancel}>
                        <Typography display="block" variant="callout-02">{i18n.t("generic_cancel")}</Typography>
                    </Button>
                </Stack>
                {content}
            </Box>
        )
    }
}

export default withSnackBar(withRouter(SearchPage));