import Home from '../pages/Home/Home';
import GetHelp from '../pages/GetHelp/GetHelp';
import Article from '../pages/Article/Article';
import ContactForm from '../pages/ContactForm/ContactForm';
import SearchPage from '../pages/SearchPage/SearchPage';
import DirList from '../pages/DirList/DirList';
import Redirect from '../pages/RedirectPage/RedirectPage';
import ChatPage from '../pages/Chatpage/Chatpage';
import Cross from '../assets/cross.svg';
import { closeWebView } from './mobileComms';
import NotFound from '../pages/NotFound/NotFound';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import FileViewer from '../pages/FileViewer/FileViewer';
import RatingPage from '../pages/RatingPage/RatingPage';
import {sendScribeEvent} from "./scribeHelper";
import FileUploader from '../components/FileUploader/FileUploader';
import FileUploader2 from '../components/FileUploader2/FileUploader2';
import ContactFormFailed from '../pages/ContactFormFailed/ContactFormFailed';

const helpCenterExit = () => {
    sendScribeEvent("help_center_back_tap")
    closeWebView()
}

interface HeaderProps {
    styles?: object,
    icon?: string,
    text?: string,
    handleNavigation?: (...args: any[]) => any,
    path?: string
}
interface Header {
    isHidden?: boolean,
    props?: HeaderProps,
}

interface Route {
    path: string,
    element: React.ReactElement | React.Component,
    header?: Header,
    isNotProtected?: boolean,
}

let routes: { [key: string]: Route; } = {
    default: {
        path: "/",
        element: <Home />,
        header: { props: { handleNavigation: helpCenterExit } }
    },
    home: {
        path: "/home",
        element: <Home />,
        header: { props: { handleNavigation: helpCenterExit } }
    },
    getHelp: {
        path: "/get-help",
        element: <GetHelp />
    },
    article: {
        path: "/article/:id",
        element: <Article />
    },
    contactUs: {
        path: "/contact-us",
        element: <ContactForm />
    },
    contactUsFailed: {
        path: "/contact-us-submission-failed",
        element: <ContactFormFailed />,
        header: { isHidden: true }
    },
    searchPage: {
        path: "/search",
        element: <SearchPage />,
        header: { isHidden: true }
    },
    dirList: {
        path: "/dir-list/:id",
        element: <DirList />
    },
    redirect: {
        path: "/redirect",
        element: <Redirect />,
        header: { props: { icon: Cross, handleNavigation: closeWebView } },
        isNotProtected: true,
    },
    error: {
        path: "/error",
        element: <ErrorBoundary />,
        isNotProtected: true,
    },
    fileViewer: {
        path: "/file-viewer",
        element: <FileViewer />,
    },
    rating: {
        path: "/rating",
        element: <RatingPage />,
        header: {
            props: {
                icon: Cross,
                styles: { position: "sticky", top: "0", py: "20px", mt: "-24px", backgroundColor: "bg-primary.main" }
            }
        }
    },
    fileUploadPOC: {
        path: "/fileUploadPOC",
        element: <FileUploader />,
        header: {
            props: {
                icon: Cross,
                styles: { position: "sticky", top: "0", py: "20px", mt: "-24px", backgroundColor: "bg-primary.main" }
            }
        }
    },
    fileUploadPOCHooked: {
        path: "/fileUploadPOCHooked",
        element: <FileUploader2 />,
        header: {
            props: {
                icon: Cross,
                styles: { position: "sticky", top: "0", py: "20px", mt: "-24px", backgroundColor: "bg-primary.main" }
            }
        }
    },
}

routes = {
    ...routes,
    chat: {
        path: "/chat",
        element: <ChatPage />,
        header: {
            props: {
                icon: Cross,
                text: "Chat",
                path: routes.home.path,
                styles: { position: "sticky", top: "0", py: "20px", mt: "-24px", backgroundColor: "bg-primary.main" }
            }
        }
    },
    notFound: {
        path: "*",
        element: <NotFound />,
    },
}

export default routes;