import { getFormConfig, getIssueTypes } from "../services/contactUs"
import { getUserDetails } from "../services/getUserDetails"
import { ApiResponse } from "../types/auth"

const getConfig = async (issueType: string) => {
    try {
        const res = await getFormConfig(issueType)
        return { res: res } as ApiResponse
    } catch (error) {
        console.error(error)
        return { res: "", error } as ApiResponse
    }
}

const getIssueTypeOptions = async () => {
    try {
        const res = await getIssueTypes()
        return { res: res.issueTypes } as ApiResponse
    } catch (error) {
        console.error(error)
        return { res: "", error } as ApiResponse
    }
}

const getUserEmail = async () => {
    try {
        const res = await getUserDetails({ cache: true })
        return { res: res.userEmail } as ApiResponse
    } catch (error) {
        console.error(error)
        return { res: "", error } as ApiResponse
    }
}

const isClicked = (id: string, target: any) => {
    const element = document.getElementById(id)
    return element?.contains(target)
}

export {
    getConfig,
    getIssueTypeOptions,
    getUserEmail,
    isClicked
}