import "./Chatbubble.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ComponentPropsWithoutRef, ReactNode, useEffect, useRef, useState } from "react";
import theme from "../../theme";
import { marked } from "marked";
import MessageRatingFeedback from "../MessageRatingFeedback/MessageRatingFeedback";
import { chatbotFeedback } from "../../services/chat";
import { getSnackbarArgs } from "../../utils/utils";
import useSnackbars from "../../context/Snackbar/Consumer";
import DOMPurify from "dompurify";
import DottedLoaderBox from "../DottedLoaderBox/DottedLoaderBox";
import { FeedbackState } from "../../constants/chat";

export type ChatBubbleProps = {
  messageId?: string,
  variant?:  "user" | "agent" | "info" | "welcome",
  content?: ReactNode,
  feedbackState?: FeedbackState,
  postFeedbackFn?: (messageId: string) => void
  time?: string,
  sender?: string | null,
  info?: string,
  messageRef?: any,
  style?: object,
  type?: "DOCUMENT" | "TEXT",
}

enum Rating {
  GOOD = "GOOD",
  BAD = "BAD"
}

const ChatBubble = (props: ChatBubbleProps & ComponentPropsWithoutRef<"div">) => {
  const [rating, setRating] = useState<Rating | null>(null)
  const [feedbackState, setFeedbackState] = useState<FeedbackState>(props.feedbackState ?? FeedbackState.DISABLED)
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false)
  const { openSnackbar, closeSnackbar } = useSnackbars()
  const chatBubbleEndRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (rating === Rating.BAD) {
      chatBubbleEndRef.current?.scrollIntoView()
    }
  }, [rating])

  const getBGColor = (variant: any) => {
    if (variant === "user") {
      return "brand-primary.main"
    } else if (variant === "agent" || variant === "welcome") {
      return "bg-tertiary.main"
    } else if (variant === "info") {
      return "bg-secondary.main"
    }
  }

  const getContentTypographyvariant = (variant: any) => {
    if (variant === "user") return "body-02"
    else if (variant === "agent" || variant === "welcome") return "body-02"
    else if (variant === "info") return "metadata-01"
  }

  const createMarkdown = (content: string) => {
    const markdown = marked(content)

    if (markdown instanceof Promise) {
      markdown.then(resolvedMarkdown => {
        return { __html: DOMPurify.sanitize(resolvedMarkdown) }
      });
    } else return { __html: DOMPurify.sanitize(markdown) }
  }

  const sendMessageFeedback = async (rating: string, feedback?: string) => {
    setIsSubmittingFeedback(true)
    try {
      const data = {
        rating,
        messageId: props.messageId ?? "",
        feedback
      }
      await chatbotFeedback(data)
      setIsSubmittingFeedback(false)
      setFeedbackState(FeedbackState.SUBMITTED)
      openSnackbar({
        uniqueKey: "chatbot-feedback", 
        secondaryMessage: "Thank you for your feedback!",
        variant: "success",
        onClose: closeSnackbar,
      })
      await props.postFeedbackFn?.(props.messageId ?? "")
    } catch(error) {
      setIsSubmittingFeedback(false)
      openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "chatbot-feedback" }))
    }
  }

  const baseProperties = {
    borderRadius: 3,
    bgcolor: getBGColor(props.variant),
    width: "fit-content",
    maxWidth: "80%",
    padding: 5,
    pb: props.time ? 3 : 5,
    display: "flex",
    flexDirection: "column",
    overflowWrap: "anywhere",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
  } as const

  const customerBubble = {
    bgcolor: "brand-primary.main",
  } as const

  const agentBubble = {
    bgcolor: "bg-tertiary.main",
  } as const

  const infoBubble = {
    bgcolor: "bg-secondary.main",
    maxWidth: "100%",
    textAlign: "center",
    py: 3,
    px: 3
  } as const
  const getCurrentvariant = (variant: string) => {
    if (variant === "user") return customerBubble
    else if (variant === "agent" || variant === "welcome") return agentBubble
    else if (variant === "info") return infoBubble
    return infoBubble
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{ ...baseProperties, ...(getCurrentvariant(props.variant || "")) }}
        mt={2}
        mb={5}
        ref={props.messageRef}
        id={props.id}
        style={props.style}
        onClick={props.onClick}
      >
        {
          props.sender &&
          <Typography variant="metadata-01-semibold"
            sx={{
              alignSelf: "flex-start",
              mb: 3
            }}>
            {props.sender}
          </Typography>
        }
        {
          typeof(props.content) !== "string" ? <Typography
            variant={getContentTypographyvariant(props.variant)}>
            {props.content}
          </Typography> : 
          <Box
            sx={{
              color: 'text-primary.main',
              fontFamily: theme.typography.fontFamily,
              typography: getContentTypographyvariant(props.variant),
              textDecoration: props.type === "DOCUMENT" ? "underline": "",
              "> p": {
                all: "unset",
              },
              "> ol": {
                margin: 0,
                paddingTop: 5,
                paddingLeft: 7,
                display: "flex",
                flexDirection: "column"
              },
              "> ul": {
                margin: 0,
                paddingTop: 5,
                paddingLeft: 7,
                display: "flex",
                flexDirection: "column"
              },
              "a": {
                  color: 'text-link.main',
              },
              'h2': {
                  typography: 'callout-01',
              },
              'h3': {
                  typography: 'heading-05',
              },
              'h4': {
                  typography: 'callout-02',
                  color: 'text-tertiary.main',
              },
              'h5': {
                  typography: 'callout-03',
                  color: 'text-tertiary.main',
              },
            }}
            dangerouslySetInnerHTML={createMarkdown(props.content || '')}>
        </Box>
        }
        <Typography
          variant={getContentTypographyvariant(props.variant)}>
          {props.info}
        </Typography>

        <Typography variant="metadata-01"
          sx={{
            alignSelf: "flex-end"
          }}>
          {props.time}
        </Typography>
        <Box ref={chatBubbleEndRef} />
        {
          feedbackState !== FeedbackState.DISABLED && 
          <Box
            sx={{
              borderTop: '1px black solid',
              marginInline:-5,
              marginBottom: -3,
              marginTop: 4,
              padding: 5,
              display: 'flex',
              gap: 3
            }}>
            {
            feedbackState === FeedbackState.SUBMITTED ? 
            <Typography variant="callout-03" sx={{
              color: "#878787"
            }}>Thank you for your feedback!</Typography>
            : 
            <>
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: rating === Rating.GOOD ? "#1a1624" : theme.palette['bg-secondary'].main,
                  display: "grid",
                  placeItems: "center",
                  width: "48px",
                  height: "48px"
                }}
                onClick={() => {
                  setRating(Rating.GOOD)
                  sendMessageFeedback(Rating.GOOD)
                }}
              >
                <Typography
                    variant="body-02"
                  >
                    👍
                  </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: rating === Rating.BAD ? "#1a1624" : theme.palette['bg-secondary'].main,
                  display: "grid",
                  placeItems: "center",
                  width: "48px",
                  height: "48px"
                }}
                onClick={() => setRating(Rating.BAD)}
              >
                <Typography
                    variant="body-02"
                  >
                    👎
                  </Typography>
              </Box>
            </>
            }
          </Box>
        }
      </Box>
      {
        feedbackState === FeedbackState.ENABLED && 
        rating === Rating.BAD && 
        <MessageRatingFeedback onSubmit={(feedback) => sendMessageFeedback(Rating.BAD, feedback)} />
      }  

      {
        isSubmittingFeedback &&
          <Box
            sx={{
              zIndex: "3000",
            }}
          >
            <Box
              sx={{
                opacity: "0.75",
                height: "100vh",
                width: "100%",
                position: "fixed",
                top: "0",
                left: "0",
                background: "black"
              }}>
            </Box>
            <DottedLoaderBox
              sx={{
                position: "fixed",
                m: 'auto',
                top: "50%",
                left: '50%',
                transform: "translate(-50%, -50%)"
              }}
            />
          </Box>
      }
    </Box>
  ); 
};

export default ChatBubble;
