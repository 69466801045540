import httpClient from './base'; //change this if your axios instance is elsewhere

type Params = {
    cache?: boolean
}
const getUserDetails = async (params?: Params) => {
    // TODO: Set headers in interceptors instead
    const res = await httpClient.get("/v1/get-user-details", {
        params
    })
    return res.data
}

export { getUserDetails }
