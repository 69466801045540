import "./ChatDivider.scss";
import PropsTypes, { InferProps } from "prop-types";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ComponentPropsWithoutRef } from "react";

const ChatDividerPropTypes = {
  text: PropsTypes.string,
  messageRef: PropsTypes.any,
}

const ChatDivider = (props: InferProps<typeof ChatDividerPropTypes & ComponentPropsWithoutRef<"div">>) => {
  return (
    //TODO: Why around a box?
    <Box id={props.id}
    ref={props.messageRef}
    >
      <Divider
        sx={{
          color: 'text-primary.main',
          verticalAlign: 'center',
          my: 5,
          '&::before': { borderColor: 'text-primary.main' },
          '&::after': { borderColor: 'text-primary.main' }
        }}>
        <Typography variant="metadata-01-semibold" sx={{ px: 5 }}>
          {props.text}
        </Typography>
      </Divider>
    </Box>
  );
};

ChatDivider.propTypes = ChatDividerPropTypes;

export default ChatDivider;
