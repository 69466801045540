import { Box, Button, InputBase, Paper, Typography } from "@mui/material"
import ListItem from "../ListItem/ListItem"
import RadioActive from '../../assets/radio-active.svg'
import RadioInactive from '../../assets/radio-inactive.svg'
import { useState } from "react"
import theme from "../../theme"
import wordsCount from "../../utils/words"


const options = [
  "The response is offensive", 
  "The information was not helpful",
  "The response is not accurate",
  "Others"
]

type MessageRatingFeedbackProps = {
  onSubmit: (feedback: string) => void
}

const MessageRatingFeedback = (props: MessageRatingFeedbackProps) => {
  const [currentSelection, setCurrentSelection] = useState("")
  const [customFeedback, setCustomFeedback] = useState("")
  
  const submitFeedback = () => {
    const feedback = currentSelection === "Others" ? customFeedback : currentSelection
    props.onSubmit(feedback)
  }

  return (
    <Box sx={{ 
        backgroundColor: "bg-secondary.main", 
        borderRadius: 3,
        p: 5,
        mt: -2,
        mb: 5
      }}
    >
        <ListItem
          sx={{
            borderBottomLeftRadius: "0 !important",
            borderBottomRightRadius: "0 !important",
            px: 0,
          }}
          primaryText={
            <Typography variant="callout-03" sx={{ color: "text-primary.main" }}>What was the issue with the response?</Typography>
          }
        />
        <Box>
          {
            options?.map((option, index) => {
              return (
                <ListItem
                  key={index}
                  id={`${option}-${index}`}
                  onClick={() => {
                      setCurrentSelection(option)
                  }}
                  sx={{
                      whiteSpace: "pre-wrap",
                      color: 'text-primary.main',
                      backgroundColor: 'bg-secondary.main',
                      px: 0,
                  }}
                  primaryText={
                      <Typography variant="body-03" sx={{ color: "text-primary.main" }}>{option}</Typography>
                  }
                  secondaryIcon={option === currentSelection ? <img src={RadioActive} alt="" /> : <img src={RadioInactive} alt="" />}
                >
                  {option}
                </ListItem>
              )
            })
            }
        </Box>
        {
        currentSelection === "Others" && 
        <Paper
          component="form"
          classes={{ root: 'border-radius-large' }}
          sx={{
            px: 5,
            py: 2,
            mt: 2,
            mb: 5,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'bg-primary.main',
            boxShadow: 'none',
            '&:Mui-disabled': {
              opacity: 0.35
            },
          }}
        >
          <Typography component="div" variant="body-02" width='100%'>
            <InputBase
              multiline
              fullWidth
              sx={{
                flex: 1,
                color: 'text-primary.main',
                'textarea::placeholder': { color: 'text-secondary.main' },
                caretColor: theme.palette["text-primary"].main,
                ...theme.typography["body-02"]
              }}
              placeholder="Leave your feedback"
              value={customFeedback}
              maxRows="4"
              onChange={(e) => setCustomFeedback(e.target.value)}
            />
          </Typography>
        </Paper>
        }
        <Button variant="outlined" color="btn-secondary" disabled={currentSelection === "" || wordsCount(customFeedback) > 20} fullWidth 
          sx={{ 
            p: 3, 
            mt:3,
            ':disabled': {
              filter: "brightness(50%)",
              border: "1px solid #FAFAFA",
            }
          }}
          onClick={submitFeedback}
        >
          <Typography color="text-primary.main" variant="metadata-01-semibold">Submit</Typography>
        </Button>
    </Box>
  )
}

export default MessageRatingFeedback