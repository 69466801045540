import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import './App.scss';
import theme, { inputGlobalStyles } from './theme';
import { SnackbarProvider } from './context/Snackbar/Provider';
import routes from "./utils/routes";
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Header from './components/Header/Header';
import { insertNewToken, goBack, webNavigate } from './utils/mobileComms';
import initDatadog from './configs/datadog';

function App() {
  const navigate = useNavigate()
  const navigator = webNavigate(navigate);

  (window as any)["insertNewToken"] = insertNewToken;
  (window as any)["goBack"] = goBack.bind(null, navigate);
  (window as any)["webNavigate"] = navigator;

  window.oncontextmenu = function (event) {
    const closestLink = (event.target as HTMLElement).closest('A')
    const closestImage = (event.target as HTMLElement).closest('IMG')
    if (closestLink || closestImage) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  };

  useEffect(() => {
    initDatadog()
  }, [])

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {inputGlobalStyles}
          <SnackbarProvider>
              <div className="App">
                <Grid
                  id="parent-grid"
                  sx={{
                    backgroundColor: 'bg-primary.main',
                    minHeight: '100vh',
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr'
                  }}
                  p={6}
                >
                  <Routes>
                    {
                      Object.keys(routes).map((route: string) => {
                        // if (!Object.prototype.hasOwnProperty.call(routes, route)) {
                        //   return
                        // }
                        const routeString = String(route)
                        const currentRoute = routes[routeString]
                        const routeElement = (
                          <>
                            {
                              !currentRoute.header?.isHidden ?
                                <Header {...currentRoute.header?.props} /> :
                                <span />
                            }
                            {currentRoute.element}
                          </>
                        )
                        if (currentRoute.isNotProtected)
                          return (
                            <Route
                              path={currentRoute.path}
                              element={routeElement}
                              key={route}
                            />
                          )

                        return (
                          <Route
                            path={currentRoute.path}
                            element={
                              <ProtectedRoute>
                                {routeElement}
                              </ProtectedRoute>
                            }
                            key={route}
                          />
                        )
                      })
                    }
                  </Routes>
                </Grid>
              </div>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
}

export default App;
